import React from "react";
import theme from "theme";
import { Theme, Link, Image, Text, LinkBox, Box, Strong, Section, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Splachic Art | A  Digital Artist Working to Something Great
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:description"} content={"Affordable art pieces by Spencer, a young, aspiring artist. Prices range from <$10 - $30 NZD \n\nDigital art ⦁ Illustration ⦁ Portraits ⦁ Commissions ⦁ Personalized gifts"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00:52:48.084Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00:52:48.084Z"} type={"image/x-icon"} />
		</Helmet>
		<Section height="700px" background="url(https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Splachic-hero-bg.svg?v=2024-11-07T23:37:19.905Z) 0% 100%/cover">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-items="center"
				justify-content="center"
				flex="1 1 0%"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				flex="0 1 auto"
				justify-content="space-between"
				margin="0px 0px 4rem 0px"
				height="560px"
			>
				<Section sm-padding="8px 0 8px 0" quarkly-title="Header-5" margin="0 0 4re 0">
					<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
					<Box
						display="flex"
						padding="12px 0"
						justify-content="space-between"
						align-items="flex-start"
						flex-direction="row"
						width="30%"
						sm-width="50%"
						sm-align-items="center"
						sm-flex-direction="row"
						sm-justify-content="flex-start"
						md-width="50%"
						lg-width="70%"
						md-justify-content="flex-start"
					>
						<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
							<Image
								src="https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00:52:48.084Z"
								display="block"
								width="40px"
								height="40px"
								srcSet="https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
							<Text
								margin="0"
								md-margin="0px 0 0px 0"
								text-align="left"
								font="normal 300 20px/1.5 --fontFamily-googlePermanentMarker"
								sm-margin="0px 0 0px 0"
								display="block"
								color="#398edf"
							>
								Splachic{" "}
							</Text>
						</LinkBox>
					</Box>
					<Components.QuarklycommunityKitMobileSidePanel
						menuPosition="full"
						breakpoint="lg"
						width="70%"
						sm-width="50%"
						md-width="50%"
						lg-width="30%"
					>
						<Override slot="Children" md-display="flex" />
						<Override
							slot="Content"
							padding="0px 0px 0px 0px"
							background="rgba(255, 255, 255, 0)"
							lg-background="#ffffff"
							lg-margin="0px 0px 0px 0px"
						/>
						<Override
							slot="Button Text"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							text-transform="uppercase"
							letter-spacing="1px"
							sm-font="normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							sm-margin="0px 2px 0px 0px"
							lg-margin="0px 0px 0px 0px"
							lg-color="#ffffff"
						/>
						<Override
							slot="Button Icon :closed"
							category="fi"
							icon={FiMenu}
							size="32px"
							padding="5px 7px 5px 7px"
							border-radius="50px"
						/>
						<Override
							slot="Button Icon"
							width="28px"
							height="28px"
							category="ai"
							icon={AiOutlineMenu}
							color="--dark"
							size="24px"
							lg-width="32px"
							lg-height="32px"
							lg-color="#ffffff"
						/>
						<Override
							slot="Cross"
							lg-width="32px"
							lg-height="32px"
							size="32px"
							top="24px"
							right="24px"
						/>
						<Box
							align-items="center"
							lg-justify-content="center"
							lg-align-items="flex-start"
							justify-content="flex-end"
							display="flex"
							lg-flex-direction="column"
							lg-margin="0px auto 0px auto"
							lg-min-width="300px"
							lg-max-width="1280px"
							lg-width="90%"
							lg-padding="24px 0px 48px 0px"
						>
							<Box
								display="none"
								lg-width="100%"
								lg-margin="0px 0px 24px 0px"
								lg-display="flex"
								lg-padding="12px 0px 12px 0px"
							>
								<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
									<Image
										src="https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00:52:48.084Z"
										display="block"
										width="40px"
										height="40px"
										srcSet="https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled993_20241108124312.png?v=2024-11-08T00%3A52%3A48.084Z&quality=85&w=3200 3200w"
										sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
									/>
									<Text
										margin="0"
										md-margin="0px 0 0px 0"
										text-align="left"
										font="--lead"
										sm-margin="0px 0 0px 0"
										display="block"
										lg-font="--lead"
									>
										Splachic
									</Text>
								</LinkBox>
							</Box>
							<Components.QuarklycommunityKitMenu
								display="flex"
								filterMode="exclude"
								filterPages="/index"
								grid-gap="12px"
								lg-flex-direction="column"
								lg-padding="6px 0px 6px 0px"
								lg-margin="0px 0px 24px 0px"
								align-items="center"
								flex-wrap="wrap"
								overflow-x="visible"
								overflow-y="visible"
								lg-align-items="flex-start"
							>
								<Override
									slot="link"
									color="--darkL2"
									hover-color="--primary"
									font="--base"
									text-decoration-line="initial"
									transition="color 0.1s ease 0s"
									lg-font="--lead"
								/>
								<Override
									slot="item-active"
									border-width="0 0 2px 0"
									border-style="solid"
									border-color="--color-darkL2"
									lg-border-width="0 0 0 2px"
								/>
								<Override slot="item" padding="6px 6px 6px 6px" lg-padding="6px 12px 6px 12px" />
								<Override slot="link-active" cursor="default" color="--darkL2" hover-color="--darkL2" />
								<Override slot="link-about" />
							</Components.QuarklycommunityKitMenu>
							<Link
								href="#about"
								color="#fff"
								flex="0 1 auto"
								align-self="auto"
								margin="0px 1rem 0px 1rem"
								text-decoration-line="initial"
								font="16px --fontFamily-googlePermanentMarker"
							>
								About
							</Link>
							<Link
								href="#contact"
								color="#fff"
								flex="0 1 auto"
								align-self="auto"
								margin="0px 1rem 0px 1rem"
								text-decoration-line="initial"
								font="16px --fontFamily-googlePermanentMarker"
							>
								Contact
							</Link>
							<Link
								href="#prices"
								color="#fff"
								flex="0 1 auto"
								align-self="auto"
								margin="0px 1rem 0px 1rem"
								text-decoration-line="initial"
								font="16px --fontFamily-googlePermanentMarker"
							>
								Prices
							</Link>
							<Link
								href="#portfolio"
								color="#fff"
								flex="0 1 auto"
								align-self="auto"
								margin="0px 1rem 0px 1rem"
								text-decoration-line="initial"
								font="16px --fontFamily-googlePermanentMarker"
							>
								Portfolio
							</Link>
							<Link
								font="normal 300 16px/1.5 --fontFamily-googlePermanentMarker"
								text-decoration-line="initial"
								margin="0px 0px 0px 16px"
								background="--color-secondary"
								border-radius="8px"
								href="https://store.splachic.art"
								color="#5ea7ec"
								padding="6px 12px 7px 12px"
								lg-margin="0px 0px 24px 0px"
								lg-padding="12px 18px 13px 18px"
								lg-font="--lead"
								hover-background="--color-accent"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Visit Shop
									<br />
								</Strong>
							</Link>
						</Box>
					</Components.QuarklycommunityKitMobileSidePanel>
				</Section>
				<Text
					margin="0px 0px 24px 0px"
					color="#fff3fa"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 --fontFamily-googlePermanentMarker"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 --fontFamily-googlePermanentMarker"
					sm-width="100%"
					font="normal 300 72px/1.2 --fontFamily-googlePermanentMarker"
				>
					Splachic ⦁ creative artspace
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="normal 500 20px/1.5 --fontFamily-sansHelvetica"
					lg-text-align="center"
					text-align="center"
					width="60%"
					md-font="normal 500 20px/1.5 --fontFamily-sansHelvetica"
					sm-font="normal 500 20px/1.5 --fontFamily-sansHelvetica"
				>
					Digital art ⦁ Illustration ⦁ Portraits ⦁ Commissions ⦁ Personalized gifts
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					max-width="60%"
					max-height="30%"
					flex-direction="row"
					align-self="center"
					sm-display="flex"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-flex-wrap="no-wrap"
				>
					<Link
						href="#about"
						padding="12px 24px 12px 24px"
						color="#5ea7ec"
						background="--color-secondary"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-googlePermanentMarker"
						border-radius="8px"
						sm-margin="0px 0px 1rem 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s linear 0s"
						hover-background="--color-accent"
						transition="background-color 0.2s linear 0s"
						margin="0px 1rem 0px 0px"
					>
						About Me
					</Link>
					<Link
						href="https://store.splachic.art"
						padding="12px 24px 12px 24px"
						color="#FFF"
						background="--color-primary"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-googlePermanentMarker"
						border-radius="8px"
						margin="0px 0px 0px 1rem"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s linear 0s"
						hover-background="--color-accent"
						transition="background-color 0.2s linear 0s"
					>
						Visit Shop
					</Link>
				</Box>
			</Box>
		</Section>
		<Section
			id="portfolio"
			padding="80px 0 80px 0"
			sm-padding="60px 0 60px 0"
			background="--color-bg"
			height="auto"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1012_20241110152939.png?v=2024-11-10T20:39:58.166Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
						pointer-events="auto"
						srcSet="https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1012_20241110152939.png?v=2024-11-10T20%3A39%3A58.166Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1012_20241110152939.png?v=2024-11-10T20%3A39%3A58.166Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1012_20241110152939.png?v=2024-11-10T20%3A39%3A58.166Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1012_20241110152939.png?v=2024-11-10T20%3A39%3A58.166Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1012_20241110152939.png?v=2024-11-10T20%3A39%3A58.166Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1012_20241110152939.png?v=2024-11-10T20%3A39%3A58.166Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1012_20241110152939.png?v=2024-11-10T20%3A39%3A58.166Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1011_20241110152758.png?v=2024-11-10T20:39:58.159Z"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
						pointer-events="auto"
						srcSet="https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1011_20241110152758.png?v=2024-11-10T20%3A39%3A58.159Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1011_20241110152758.png?v=2024-11-10T20%3A39%3A58.159Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1011_20241110152758.png?v=2024-11-10T20%3A39%3A58.159Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1011_20241110152758.png?v=2024-11-10T20%3A39%3A58.159Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1011_20241110152758.png?v=2024-11-10T20%3A39%3A58.159Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1011_20241110152758.png?v=2024-11-10T20%3A39%3A58.159Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1011_20241110152758.png?v=2024-11-10T20%3A39%3A58.159Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1007.png?v=2024-11-10T20:39:58.057Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
						pointer-events="auto"
						srcSet="https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1007.png?v=2024-11-10T20%3A39%3A58.057Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1007.png?v=2024-11-10T20%3A39%3A58.057Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1007.png?v=2024-11-10T20%3A39%3A58.057Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1007.png?v=2024-11-10T20%3A39%3A58.057Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1007.png?v=2024-11-10T20%3A39%3A58.057Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1007.png?v=2024-11-10T20%3A39%3A58.057Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1007.png?v=2024-11-10T20%3A39%3A58.057Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1008_20241110151903.png?v=2024-11-10T20:39:58.075Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
						pointer-events="auto"
						srcSet="https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1008_20241110151903.png?v=2024-11-10T20%3A39%3A58.075Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1008_20241110151903.png?v=2024-11-10T20%3A39%3A58.075Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1008_20241110151903.png?v=2024-11-10T20%3A39%3A58.075Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1008_20241110151903.png?v=2024-11-10T20%3A39%3A58.075Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1008_20241110151903.png?v=2024-11-10T20%3A39%3A58.075Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1008_20241110151903.png?v=2024-11-10T20%3A39%3A58.075Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1008_20241110151903.png?v=2024-11-10T20%3A39%3A58.075Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="normal 700 72px/1.2 --fontFamily-sans"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Splachic's Artworks
				</Text>
				<Text margin="0px 0px 0px 0px" color="--lightD1" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" lg-text-align="center">
					A dedicated section showcasing original art pieces by Spencer at Splachic. Each piece includes descriptions and insights into the creative process, inviting visitors to immerse themselves in the OC's backstories.
				</Text>
				<Link
					href="https://store.splachic.art"
					padding="12px 24px 12px 24px"
					color="#FFF"
					background="--color-primary"
					text-decoration-line="initial"
					font="normal 300 20px/1.5 --fontFamily-googlePermanentMarker"
					border-radius="8px"
					sm-margin="00.5rempx 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s linear 0s"
					hover-background="--color-accent"
					transition="background-color 0.2s linear 0s"
					margin="2rem 0px 0px 0px"
				>
					Visit Shop
				</Link>
			</Box>
		</Section>
		<Section
			background="--color-bg"
			align-items="center"
			align-content="space-around"
			min-height="800px"
			flex-wrap="wrap"
			id="prices"
		>
			<Override slot="SectionContent" text-align="center" />
			<Text as="h1" font="normal 700 72px/1.2 --fontFamily-sans" md-font="--headline2" color="--light">
				Prices
			</Text>
			<Text margin="40px 0px 0px 0px" font="16px --fontFamily-sansHelvetica" color="--lightD1">
				Affordable art pieces by Spencer, a young, aspiring artist.
				<br />
				Prices range from &lt;$10 - $30 NZD
			</Text>
			<Section background="--color-bg" height="auto">
				<Override
					slot="SectionContent"
					align-items="center"
					flex-direction="row"
					align-self="auto"
					display="flex"
					justify-content="space-around"
					flex-wrap="wrap"
					height="max-content"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="1px"
					border-color="--color-lightD1"
					background="--color-bg"
					width="270px"
					height="450px"
					padding="1rem 1rem 1rem 1rem"
					border-radius="2rem"
					display="flex"
					align-items="center"
					justify-content="space-between"
					flex-direction="column"
					border-style="solid"
					flex-wrap="no-wrap"
					margin="0.5rem 0.5rem 0.5rem 0.5rem"
				>
					<Image
						src="https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1013_20241110153222.png?v=2024-11-10T20:39:58.025Z"
						display="block"
						border-radius="2rem"
						pointer-events="auto"
						srcSet="https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1013_20241110153222.png?v=2024-11-10T20%3A39%3A58.025Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1013_20241110153222.png?v=2024-11-10T20%3A39%3A58.025Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1013_20241110153222.png?v=2024-11-10T20%3A39%3A58.025Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1013_20241110153222.png?v=2024-11-10T20%3A39%3A58.025Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1013_20241110153222.png?v=2024-11-10T20%3A39%3A58.025Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1013_20241110153222.png?v=2024-11-10T20%3A39%3A58.025Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1013_20241110153222.png?v=2024-11-10T20%3A39%3A58.025Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Section flex-direction="column">
						<Override slot="SectionContent" align-items="flex-start" display="flex" grid-row-gap="0.2rem" />
						<Text margin="0px 0px 0px 0px" font="600 1.5rem --fontFamily-sans">
							Spencer's Self Portrait
						</Text>
						<Text margin="0px 0px 0px 0px" font="3 1.2rem --fontFamily-sans" text-align="left">
							Splachic's official self portrait
						</Text>
						<Text margin="0px 0px 0px 0px" font="1.6rem --fontFamily-sans">
							$8 NZD
						</Text>
					</Section>
					<Link
						href="https://ko-fi.com/s/b9c587b46d"
						padding="12px 24px 12px 24px"
						color="#FFF"
						background="--color-primary"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-googlePermanentMarker"
						border-radius="8px"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s linear 0s"
						hover-background="--color-accent"
						transition="background-color 0.2s linear 0s"
						align-self="flex-start"
					>
						Find In Store
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="1px"
					border-color="--color-lightD1"
					background="--color-bg"
					width="270px"
					height="450px"
					padding="1rem 1rem 1rem 1rem"
					border-radius="2rem"
					display="flex"
					align-items="center"
					justify-content="space-between"
					flex-direction="column"
					border-style="solid"
					flex-wrap="no-wrap"
					margin="0.5rem 0.5rem 0.5rem 0.5rem"
				>
					<Image
						src="https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1010_20241110152604.png?v=2024-11-10T20:39:58.052Z"
						display="block"
						border-radius="2rem"
						pointer-events="auto"
						srcSet="https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1010_20241110152604.png?v=2024-11-10T20%3A39%3A58.052Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1010_20241110152604.png?v=2024-11-10T20%3A39%3A58.052Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1010_20241110152604.png?v=2024-11-10T20%3A39%3A58.052Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1010_20241110152604.png?v=2024-11-10T20%3A39%3A58.052Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1010_20241110152604.png?v=2024-11-10T20%3A39%3A58.052Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1010_20241110152604.png?v=2024-11-10T20%3A39%3A58.052Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1010_20241110152604.png?v=2024-11-10T20%3A39%3A58.052Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Section flex-direction="column">
						<Override slot="SectionContent" align-items="flex-start" grid-row-gap="0.2rem" display="flex" />
						<Text margin="0px 0px 0px 0px" font="600 1.5rem --fontFamily-sans">
							Land Ho!
						</Text>
						<Text margin="0px 0px 0px 0px" font="3 1.2rem --fontFamily-sans" text-align="left">
							Artwork of my OC Agatha{" "}
							<br />
							{" "}
						</Text>
						<Text margin="0px 0px 0px 0px" font="1.6rem --fontFamily-sans">
							$10 NZD
						</Text>
					</Section>
					<Link
						href="https://ko-fi.com/s/596a3ceee3"
						padding="12px 24px 12px 24px"
						color="#FFF"
						background="--color-primary"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-googlePermanentMarker"
						border-radius="8px"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s linear 0s"
						hover-background="--color-accent"
						transition="background-color 0.2s linear 0s"
						align-self="flex-start"
					>
						Find In Store
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="1px"
					border-color="--color-lightD1"
					background="--color-bg"
					width="270px"
					height="450px"
					padding="1rem 1rem 1rem 1rem"
					border-radius="2rem"
					display="flex"
					align-items="center"
					justify-content="space-between"
					flex-direction="column"
					border-style="solid"
					flex-wrap="no-wrap"
					margin="0.5rem 0.5rem 0.5rem 0.5rem"
				>
					<Image
						src="https://uploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1009_20241110152229.png?v=2024-11-10T20:39:58.121Z"
						display="block"
						border-radius="2rem"
						pointer-events="auto"
						srcSet="https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1009_20241110152229.png?v=2024-11-10T20%3A39%3A58.121Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1009_20241110152229.png?v=2024-11-10T20%3A39%3A58.121Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1009_20241110152229.png?v=2024-11-10T20%3A39%3A58.121Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1009_20241110152229.png?v=2024-11-10T20%3A39%3A58.121Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1009_20241110152229.png?v=2024-11-10T20%3A39%3A58.121Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1009_20241110152229.png?v=2024-11-10T20%3A39%3A58.121Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672bee6c9e74900024fe3dd9/images/Untitled1009_20241110152229.png?v=2024-11-10T20%3A39%3A58.121Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Section flex-direction="column">
						<Override slot="SectionContent" align-items="flex-start" grid-row-gap="0.2rem" display="flex" />
						<Text margin="0px 0px 0px 0px" font="600 1.5rem --fontFamily-sans">
							The Brothers
						</Text>
						<Text margin="0px 0px 0px 0px" font="3 1.2rem --fontFamily-sans" text-align="left">
							My OC's Koby and Soren in{" "}
							<br />
							medieval times.
						</Text>
						<Text margin="0px 0px 0px 0px" font="1.6rem --fontFamily-sans">
							$15 NZD
						</Text>
					</Section>
					<Link
						href="https://ko-fi.com/s/d9d4de3d2a"
						padding="12px 24px 12px 24px"
						color="#FFF"
						background="--color-primary"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-googlePermanentMarker"
						border-radius="8px"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s linear 0s"
						hover-background="--color-accent"
						transition="background-color 0.2s linear 0s"
						align-self="flex-start"
					>
						Find In Store
					</Link>
				</Box>
			</Section>
		</Section>
		<Section
			id="about"
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-bg"
			height="auto"
		>
			<Override slot="SectionContent" height="auto" />
			<Text
				as="h1"
				font="normal 700 72px/1.2 --fontFamily-sans"
				md-font="--headline2"
				margin="10px 0 0 0"
				color="--light"
			>
				About Me
			</Text>
			<Text as="p" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" margin="40px 0 20px 0" color="--lightD1">
				Hey there!
I'm a young artist who creates original pieces. My art style was 
inspired by the popular anime My Hero Academia and has evolved over the 
years. 
I create both generic and commissioned pieces, including Anime-like 
scenes, People, Anthropomorphic characters, Chibi characters, and 
Concept art or Character designs.
I don't draw realism. I may put out a semi-realistic piece now and then,
 but it’s just something I do for fun ever so often.
My other hobbies include singing, theatre, dancing, going to the gym, 
and studying (about sports and cats of course.)
I like fashion, and creating stories to bring my characters to life.
I hope you enjoy browsing through my artwork! :)
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				margin="2rem 0px 0px 0px"
				md-margin="4rem 0px 0px 0px"
				lg-align-items="flex-end"
				lg-display="flex"
				lg-flex-direction="row"
				lg-justify-content="center"
			>
				<Link
					href="https://store.splachic.art"
					padding="12px 24px 12px 24px"
					color="#FFF"
					background="--color-primary"
					text-decoration-line="initial"
					font="normal 300 20px/1.5 --fontFamily-googlePermanentMarker"
					border-radius="8px"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s linear 0s"
					hover-background="--color-accent"
					transition="background-color 0.2s linear 0s"
				>
					Visit Shop
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-bg" id="contact">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="normal 700 72px/1.2 --fontFamily-sans"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contact{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--lightD1" text-align="center">
					Reach out to me for any commissions or questions
				</Text>
				<Link
					href="mailto:splachic@gmail.com?Subject Goes Here"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="underline"
					margin="0px 0px 16px 0px"
					hover-color="--primary"
				>
					splachic@gmail.com
				</Link>
			</Box>
		</Section>
		<Section
			background-color="--dark"
			text-align="center"
			padding="32px 0"
			quarkly-title="Footer-1"
			background="none"
		>
			<List
				margin="0px 0px 0px 0px"
				padding="12px 0px 12px 0px"
				list-style-type="none"
				as="ul"
				display="flex"
				align-items="center"
				justify-content="center"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="center"
				sm-display="grid"
			>
				<Link
					href="#about"
					color="--darkL2"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="20px/30px --fontFamily-googlePermanentMarker"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
				>
					About
				</Link>
				<Link
					href="#portfolio"
					color="--darkL2"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="20px/30px --fontFamily-googlePermanentMarker"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
				>
					Portfolio
				</Link>
				<Link
					href="https://store.splachic.art"
					color="--darkL2"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="20px/30px --fontFamily-googlePermanentMarker"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
					lg-href="https://store.splachic.art"
				>
					Shop
				</Link>
				<Link
					href="#contact"
					color="--darkL2"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="20px/30px --fontFamily-googlePermanentMarker"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
					lg-href="#contact"
				>
					Contact
				</Link>
			</List>
			<Link
				href="mailto:splachic@gmail.com"
				text-decoration-line="none"
				variant="--base"
				color="--primary"
				hover-color="--primary"
			>
				splachic@gmail.com
			</Link>
			<Link
				href="https://bgjar.com"
				text-decoration-line="none"
				variant="--base"
				color="#f0ccd7"
				hover-color="--accent"
				margin="1rem 0px 0px 0px"
			>
				Free SVG Background by BGJar
			</Link>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"672bee6c9e74900024fe3dd7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});